<!--
 * @Author: v_yerun v_yerun@baidu.com
 * @Date: 2023-11-02 17:22:56
 * @LastEditors: v_yerun v_yerun@baidu.com
 * @LastEditTime: 2025-03-10 11:38:13
 * @FilePath: /banquan-ti/baidu/feedback/copyright/src/main/resources/copyright/src/components/subject/Subject.vue
 * @Description:  帐号主体信息认证页面
-->
<template>
    <!-- 主体认证页面 -->
    <div class="subject">
        <main-steps></main-steps>
        <div class="subject_main">
            <div class="subject_main_top">
                <span>
                    <img :src="titleIcon" />
                </span>
                <span class="subject_main_top_font">
                    投诉人帐号主体信息
                </span>
            </div>
            <div class="alert">
                <el-alert
                    type="warning"
                    show-icon
                    @close="close"
                    v-show="showaAlert"
                >
                    <template slot="title">
                        <p class="alert-text">
                            您的帐号主体材料未通过审核，请您重新填写并提交，失败详情请至
                            <span @click="goRecord">我的反馈</span>
                            中查看
                        </p>
                    </template>
                </el-alert>
            </div>
            <el-form
                label-position="right"
                label-width="125px"
            >
                <el-form-item label="投诉人类型">
                    <el-radio-group
                        v-model="complainantType"
                        :disabled="disabled"
                        size="small"
                        fill="rgba(45,99,224,0.14)"
                        text-color="#2D63E0"
                    >
                        <el-radio-button label="组织"> </el-radio-button>
                        <el-radio-button
                            class="person-radio"
                            label="个人"
                        >
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <div class="subject-tip">
                    请填写您本人或组织的真实身份信息， 若您是代理人， 注意此处不要写成您所代理的权利人信息
                </div>
                <!-- 组织 -->
                <organization
                    v-show="isshow"
                    :visible="isshow"
                    @edit="onEdit"
                ></organization>
                <!-- 个人 -->
                <personal
                    v-show="!isshow"
                    :visible="!isshow"
                    @edit="onEdit"
                ></personal>
            </el-form>
        </div>
    </div>
</template>

<script>
import MainSteps from '@/views/MainSteps.vue';
import Organization from '@/components/subject/Organization.vue';
import Personal from '@/components/subject/Personal.vue';
import { info } from '@/api/getData.js';

export default {
    name: 'subject',
    components: {
        'main-steps': MainSteps, // 步骤条
        organization: Organization, // 组织
        personal: Personal, // 个人
    },
    data() {
        return {
            titleIcon: require('@/assets/img/title2.png'), // 标题图标
            complainantType: '个人', // 投诉人类型
            disabled: true, // 编辑状态，默认不可编辑
            isshow: false, // 账号主体类型，false为个人，true为组织
            showaAlert: false, // 警告框显示隐藏
        };
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.Organization();
            this.$refs.Personal();
        });
    },
    watch: {
        // 监听投诉人类型，切换帐号主体信息
        complainantType(newValue) {
            if (newValue === '组织') {
                this.isshow = true;
            } else {
                this.isshow = false;
            }
            sessionStorage.setItem('type1', this.isshow);
            this.$store.commit('changeType', this.isshow);
        },
    },
    mounted() {
        // 读取缓存 获取是否编辑状态
        if (sessionStorage.getItem('isEdit')) {
            this.disabled = false;
        }
        this.getInfor(); // 获取帐号主体信息
    },
    methods: {
        /**
         * @description: 跳转到我的反馈页面
         * @return {*}
         */
        goRecord() {
            this.$router.push({ path: '/record' });
        },
        /**
         * @description: 关闭认证失败提示信息
         * @return {*}
         */
        close() {
            this.showaAlert = false;
        },
        /**
         * @description: 开始编辑
         * @return {*}
         */
        onEdit(type) {
            this.disabled = type !== 1;
            sessionStorage.setItem('isEdit', type); // 会话缓存编辑状态
        },
        /**
         * @description: 获取帐号主体信息
         * @return {*}
         */
        async getInfor() {
            try {
                const res = await info();
                const { code, data } = res;
                if (code === 200 && data) {
                    // 本地缓存帐号主体信息验证是否通过
                    sessionStorage.setItem('extras', res.data.extras.public_payment);
                    const { user_type, user_status } = data;
                    // 帐号主体信息审核状态，若为2则显示警告框，若不为1和2则可编辑
                    if (user_status === 2) {
                        this.disabled = false;
                        this.showaAlert = true;
                    } else {
                        this.showaAlert = false;
                    }
                    // 帐号主体类型，1为个人，2为组织，其他则进入编辑状态
                    if (user_type === 1) {
                        this.complainantType = '个人';
                        this.isshow = false;
                    } else if (user_type === 2) {
                        this.complainantType = '组织';
                        this.isshow = true;
                    } else {
                        this.disabled = false;
                    }
                    if (sessionStorage.getItem('type')) {
                        if (sessionStorage.getItem('type') === 'true') {
                            this.complainantType = '组织';
                            this.isshow = true;
                        } else {
                            this.complainantType = '个人';
                            this.isshow = false;
                        }
                        sessionStorage.setItem('type1', this.isshow);
                    } else {
                        this.$store.commit('changeType', this.isshow);
                        sessionStorage.setItem('type1', this.isshow);
                    }
                    this.disabled = sessionStorage.getItem('isEdit') !== '1';
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
};
</script>

<style lang="less" scoped>
.subject-tip {
    width: 735px;
    text-align: left;
    text-indent: 15px;
    margin: 5px 0 5px 115px;
    background: #fff;
    height: 30px;
    line-height: 30px;
    color: rgb(217, 218, 227);
    font-size: 14px;
}

.subject {
    background: #ffffff;
    box-shadow: 0 10px 32px 10px rgba(175, 188, 220, 0.02);
    border-radius: 4px;
    width: 1200px;
    margin: auto;
}

::v-deep .alert {
    width: 580px;
    margin: 20px 0;
    position: relative;
    left: 40px;

    .alert-text span {
        color: rgb(41, 105, 176);
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    [class*=' el-icon-'],
    [class^='el-icon-']:hover {
        color: #e6a23c;
    }

    .el-pagination [class*=' el-icon-'],
    [class^='el-icon-']:hover {
        color: #e6a23c;
    }

    .el-alert__closebtn {
        top: 12px;
    }
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label {
    display: none;
}

::v-deep .el-radio-button__inner:hover {
    color: #2d63e0;
}

::v-deep .el-radio-button.is-active span {
    border-color: #2d63e0 !important;
}

::v-deep .el-radio-button span {
    background: #f5f7fc;
    border-radius: 4px;
    box-shadow: none !important;
    border: 1px solid transparent;
}

::v-deep .el-radio-button__orig-radio:disabled+.el-radio-button__inner {
    background: #f5f7fc;
}

::v-deep .el-radio-button:first-child .el-radio-button__inner {
    border: 1px solid transparent;
}

::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
}

::v-deep .el-upload {
    width: 120px;
    height: 75px;
}

::v-deep .el-upload--picture-card {
    border: 1px dashed rgb(207, 212, 223);
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-actions {
    transition: none;
}

::v-deep .el-upload-list__item {
    transition: none;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
    font-size: 14px;
}

::v-deep .el-radio-button__inner {
    border: none;
}

::v-deep .is-active span {
    box-shadow: rgb(45, 99, 224 / 14%) 0px 0px 0px 0px !important;
}

.subject_main {
    min-height: 100vh;
    width: 800px;
    margin-left: 245px;
}

/deep/ .el-radio-button:first-child {
    .el-radio-button__inner {
        border-radius: 4px;
    }
}

/deep/ .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 4px;
}

.subject_main_top {
    width: 197px;
    height: 20px;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-top: 20px;
}

.subject_main_top span img {
    width: 11px;
    height: 11px;
    position: relative;
    top: -3px;
}

.subject_main_top_font {
    font-size: 20px;
    color: #1e2024;
    line-height: 20px;
    height: 20px;
    font-weight: bold;
}

.el-form-item {
    font-size: 14px;
    color: #383d47;
    text-align: left;
    margin-bottom: 0;
}

.person-radio {
    margin-left: 12px;
    border-radius: 4px;
}
</style>
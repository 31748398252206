<!--
 * @Author: v_yerun v_yerun@baidu.com
 * @Date: 2024-09-14 11:28:11
 * @LastEditors: v_yerun v_yerun@baidu.com
 * @LastEditTime: 2025-03-11 15:56:22
 * @FilePath: /banquan-ti/baidu/feedback/copyright/src/main/resources/copyright/src/components/subject/Personal.vue
 * @Description: 账号主体信息-个人
-->
<template>
    <!-- 个人页面 -->
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-position="right"
        :rules="rules"
        label-width="125px"
        size="small"
        class="demo-ruleForm clearfix"
    >
        <el-form-item
            label="姓名"
            prop="cate"
        >
            <el-input
                @change="onChangeForm"
                v-model.trim="ruleForm.cate"
                :disabled="ruleForm.disabled"
                placeholder="请输入姓名"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="身份证号码"
            prop="ID"
        >
            <el-input
                @change="onChangeForm"
                :disabled="ruleForm.disabled"
                v-model.trim="ruleForm.ID"
                placeholder="请输入身份证号码"
            ></el-input>
        </el-form-item>
        <el-form-item prop="fileList">
            <template #label>
                <span class="required-icon">*</span>
                <span>证件原件上传</span>
            </template>
            <div class="imgtype">
                <el-upload
                    :disabled="ruleForm.disabled"
                    list-type="picture-card"
                    class="upload-demo"
                    :action="baseUrl + '/upload/pic/1'"
                    :on-success="handleAvatarSuccess1"
                    :before-upload="beforeAvatarUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove1"
                    :file-list="fileList1"
                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                >
                    <img
                        v-if="!fileList1.length"
                        class="on"
                        src="@/assets/img/head.png"
                    />
                    <p>头像面</p>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img
                        width="100%"
                        :src="dialogImageUrl"
                    />
                </el-dialog>
                <el-upload
                    :disabled="ruleForm.disabled"
                    list-type="picture-card"
                    class="upload-demo"
                    :action="baseUrl + '/upload/pic/1'"
                    :on-success="handleAvatarSuccess2"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove2"
                    :file-list="fileList2"
                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                >
                    <img
                        v-if="!fileList2.length"
                        class="on"
                        src="@/assets/img/emblem.png"
                    />
                    <p>国徽面</p>
                </el-upload>
                <el-upload
                    class="upload-demo"
                    list-type="picture-card"
                    :action="baseUrl + '/upload/pic/1'"
                    :disabled="ruleForm.disabled"
                    :on-success="handleAvatarSuccess3"
                    :before-upload="beforeAvatarUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove3"
                    :file-list="fileList3"
                    accept="image/png, image/jpeg, image/jpg, image/bmp"
                >
                    <img
                        v-if="!fileList3.length"
                        class="on"
                        src="@/assets/img/hold.png"
                    />
                    <p>手持证件</p>
                </el-upload>
            </div>
            <div class="upload_tip">
                <p class="p1">请上传身份证等有效证件的正反面照片或扫描件以及您的手持身份证照片，要求清晰无遮挡，</p>
                <p class="p2">支持.jpg.jpeg.png.bmp，每张图片大小不超过5M</p>
            </div>
        </el-form-item>
        <el-form-item
            label="邮箱"
            prop="email"
        >
            <el-input
                v-model.trim="ruleForm.email"
                @change="onChangeForm"
                placeholder="请输入邮箱"
                :disabled="ruleForm.disabled"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="手机号"
            prop="tel"
        >
            <el-input
                v-model.trim="ruleForm.tel"
                placeholder="请填写联系人手机号"
                @change="changetel"
                style="width: 310px; height: 32px"
                :disabled="ruleForm.disabled"
            ></el-input>
            <el-button
                class="code_btn"
                :class="[ruleForm.tel ? '' : 'null']"
                :disabled="disabled"
                @click="getCodes"
            >
                {{ catchCode }}
            </el-button>
        </el-form-item>
        <el-form-item
            v-if="ruleForm.yanoff"
            class="last"
            label="验证码"
            prop="password"
        >
            <el-input
                :disabled="ruleForm.disabled"
                v-model.trim="ruleForm.password"
                @keyup.enter.native="submitPhone"
                placeholder="请填写验证码"
                style="width: 134px"
            ></el-input>
        </el-form-item>
        <el-form-item>
            <next-tick
                :btn1="1"
                :btn2="0"
                :btn3="1"
                :btn4="ruleForm.update ? 1 : 0"
                class="tick"
                @edit="onEdit"
                @nextTick="nextTick('ruleForm')"
            ></next-tick>
        </el-form-item>
    </el-form>
</template>

<script>
import { sendCode, keyword, info, verifyTelAndUserInfo } from '@/api/getData.js';
import NextTick from '@/components/NextTick.vue';

export default {
    name: 'personal',
    components: {
        'next-tick': NextTick, // 下一步按钮
    },
    data() {
        return {
            off: true,
            baseUrl: process.env.NODE_ENV === 'production' ? location.origin : '/proxy', // 文件上传地址
            fileList1: [], // 用于展示-头像面
            fileList2: [], // 用于展示-国徽面
            fileList3: [], // 用于展示-手持证件
            imageUrl: '', // 头像面
            imagesUrl: '', // 国徽面
            imagerUrl: '', // 手持证件
            disabled: false, // 获取验证码按钮是否禁用
            setTimeNum: 60, // 短信验证码倒计时时间（秒）
            catchCode: '获取验证码', // 获取验证码按钮文字
            dialogImageUrl: '', // 图片预览地址
            dialogVisible: false, // 图片预览弹窗是否显示
            timer: null, // 是否已存在计时器
            ruleForm: {
                cate: '',
                ID: '',
                email: '',
                yanoff: false,
                tel: '',
                password: '',
                disabled: false, // 表单是否禁用
                update: false,
            }, // 信息认证表单数据-个人
            rules: {
                cate: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '您输入的姓名长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                    {
                        message: '请填写您的有效姓名',
                        trigger: 'blur',
                    },
                ],
                ID: [
                    { required: true, message: '请输入身份证号码', trigger: 'blur' },
                    {
                        pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                        message: '您输入的身份证号码不正确,请重新输入',
                        trigger: 'blur',
                    },
                    {
                        min: 18,
                        max: 18,
                        message: '您输入的身份证号码长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                ],
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    {
                        pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                        message: '请输入正确的邮箱地址',
                        trigger: 'blur',
                    },
                ],
                fileList: [
                    { validator: this.validateFileList, trigger: 'change' }
                ],
                tel: [
                    { required: true, message: '请填写联系人手机号', trigger: 'blur' },
                    {
                        tmin: 11,
                        max: 11,
                        message: '您输入的手机号码长度不正确,请重新输入',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请填写验证码', trigger: 'blur' },
                    {
                        pattern: /^[0-9]{4}$/,
                        message: '请填写正确的验证码',
                        trigger: 'blur',
                    },
                ],
            }, // 表单验证规则
        };
    },
    props: {
        // 当前组件是否显示
        visible: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        'ruleForm.tel': {
            handler(val) {
                const pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (val && pattern.test(val)) {
                    if (this.setTimeNum === 60) {
                        this.disabled = false;
                    }
                }
            },
        },
        visible: {
            handler(val) {
                let isEdit = sessionStorage.getItem('isEdit') || false;
                // 读取缓存 获取是否编辑状态
                if (val && isEdit === '1') {
                    this.ruleForm.update = true;
                }
            },
        },
    },

    created() {
        this.beforeunload();
    },
    mounted() {
        this.init();
    },
    methods: {
        /**
         * @description: 初始化数据
         * @return {*}
         */
        init() {
            this.ruleForm.password = '';
            let ruleForm = sessionStorage.getItem('subject');
            let offrus = {};
            if (ruleForm && ruleForm !== 'undefined') {
                try {
                    offrus = JSON.parse(ruleForm);
                } catch (error) {
                    offrus = ruleForm;
                }
            }
            if (!offrus.update) {
                info().then((res) => {
                    if (res?.code === 200 && res?.data?.user_type === 1) {
                        if (res.data.user_status === 2) {
                            this.ruleForm.disabled = false;
                            this.ruleForm.update = true;
                            this.$emit('edit', 1);
                        } else {
                            this.ruleForm.disabled = true;
                            this.ruleForm.update = false;
                            this.$emit('edit', 0);
                            this.ruleForm.cate = res.data.user_name;
                            [this.imageUrl, this.imagesUrl, this.imagerUrl] = res.data.pics_url;
                            this.fileList1 = [{ url: this.imageUrl }];
                            this.fileList2 = [{ url: this.imagesUrl }];
                            this.fileList3 = [{ url: this.imagerUrl }];
                            this.ruleForm.ID = res.data.id_card;
                            this.ruleForm.email = res.data.email;
                            this.ruleForm.tel = res.data.mobile_phone;
                        }
                    } else if (res?.data?.user_type !== 2) {
                        // 没有认证信息，直接进入需要编辑状态
                        this.ruleForm.update = true;
                        this.$emit('edit', 1); // 同步编辑状态到父组件，以便更新按钮显示
                    }
                });
            } else {
                if (ruleForm && ruleForm !== 'undefined') {
                    if (offrus.type === 'false') {
                        this.ruleForm = offrus;
                        [this.imageUrl, this.imagesUrl, this.imagerUrl] = JSON.parse(ruleForm).imageUrl;
                        this.fileList1 = [{ url: this.imageUrl }];
                        this.fileList2 = [{ url: this.imagesUrl }];
                        this.fileList3 = [{ url: this.imagerUrl }];
                        this.fileList = this.ruleForm.fileList;
                        this.verifyStatus = this.ruleForm.verifyStatus;
                    } else {
                        // 没有认证信息，直接进入需要编辑状态
                        this.ruleForm.update = true;
                        this.$emit('edit', 1); // 同步编辑状态到父组件，以便更新按钮显示
                    }
                }
            }
            let setTime;
            try {
                setTime = JSON.parse(sessionStorage.getItem('getTime'));
            } catch (error) {
                setTime = {};
            }
            const { time, timer, verifyStatus } = setTime || {};
            if (timer && !verifyStatus) {
                this.setTimeNum = time;
                this.verifyStatus = verifyStatus;
                this.setTime();
            }
        },
        /**
         * @description: 验证身份证件文件上传是否完整
         * @param {*} rule
         * @param {*} value
         * @param {*} callback
         * @return {*}
         */
        validateFileList(rule, value, callback) {
            if (this.fileList1.length && this.fileList2.length && this.fileList3.length) {
                callback(); // 校验通过
            } else {
                callback(new Error('请上传完整的证件图片（头像面、国徽面、手持证件）'));
            }
        },
        /**
         * @description: 进入编辑状态
         * @return {*}
         */
        onEdit() {
            this.ruleForm.disabled = false;
            this.ruleForm.update = true;
            this.$emit('edit', 1);
        },
        /**
         * @description: 表单字段有变化时，需要重新校验验证码
         * @return {*}
         */
        onChangeForm() {
            this.ruleForm.yanoff = true;
        },
        /**
         * @description: 手机号change事件，判断是否禁用验证码获取按钮
         * @return {*}
         */
        changetel() {
            this.onChangeForm();
            this.ruleForm.password = '';
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (reg.test(this.ruleForm.tel)) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
        /**
         * @description: 下一步
         * @return {*}
         */
        nextTick() {
            this.$refs.ruleForm.validate(async (valid, rule) => {
                if (valid) {
                    try {
                        if (this.ruleForm.yanoff) {
                            const {
                                ID = '',
                                email = '',
                                password = '',
                                tel = '',
                                cate = '',
                            } = this.ruleForm || {};
                            const imgList = [this.imageUrl, this.imagesUrl, this.imagerUrl];
                            const res = await verifyTelAndUserInfo({
                                mobile_phone: tel,
                                verify_code: password,
                                user_info: {
                                    user_type: 1,
                                    user_name: cate,
                                    id_card: ID,
                                    pics_url: imgList,
                                    email,
                                    mobile_phone: tel,
                                }
                            });
                            const { code, message } = res;
                            if (code === 200) {
                                sessionStorage.setItem('active', 1);
                                if (!this.ruleForm.disabled) {
                                    sessionStorage.setItem('isEdit', 1); // 标记为编辑状态
                                    let type1 = sessionStorage.getItem('type1');
                                    sessionStorage.setItem('type', type1);
                                }
                                this.suces();
                            } else {
                                this.$message.error(message || '认证失败');
                                return false;
                            }
                        } else {
                            sessionStorage.setItem('active', 1);
                            if (!this.ruleForm.disabled) {
                                let type1 = sessionStorage.getItem('type1');
                                sessionStorage.setItem('type', type1);
                                sessionStorage.setItem('isEdit', 1); // 标记为编辑状态
                            }
                            this.suces();
                        }
                    } catch (error) {
                        this.$message.error(error?.message || '认证失败');
                    }
                } else {
                    let hasError = false; // 标记是否有错误信息
                    for (const key in rule) {
                        if (rule[key]?.[0]?.message) {
                            this.$message.error(rule[key][0].message);
                            hasError = true;
                            break; // 终止循环，避免多个弹窗
                        }
                    }
                    if (!hasError) {
                        // 只有当所有 rule 都没有 message 时才执行
                        this.$message.error('请完善认证信息！');
                    }
                    return false;
                }
            });
        },
        /**
         * @description: 将认证信息缓存起来
         * @return {*}
         */
        suces() {
            let imgList = [this.imageUrl, this.imagesUrl, this.imagerUrl];
            let subject = {
                ...this.ruleForm,
                imageUrl: imgList,
                type: sessionStorage.getItem('type1'),
            };
            // 将表单信息缓存起来
            keyword({
                key_word: '',
                owner_type: 0,
                lastPageNo: 0,
                page: 1,
                size: 10,
            }).then((res) => {
                sessionStorage.setItem('subject', JSON.stringify(subject));
                if (res?.data?.records?.length) {
                    this.$router.push({
                        path: '/hasownership',
                    });
                } else {
                    this.$router.push({
                        path: '/noownership',
                    });
                }
            });
        },
        yan() {
            let ruleForm = {
                cate: this.ruleForm.cate,
                ID: this.ruleForm.ID,
                email: this.ruleForm.email,
                tel: this.ruleForm.tel,
                password: this.ruleForm.password,
            };
            for (let key in ruleForm) {
                if (ruleForm[key].length === 0) {
                    this.off = false;
                }
            }
            let imgList = [this.imageUrl, this.imagesUrl, this.imagerUrl];
            this.off = imgList.every((item) => {
                return item;
            });
            if (!this.off) {
                return {
                    off: this.off,
                    msg: '请填写内容',
                };
            }
            let obj = {};
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    obj = {
                        off: this.off,
                        msg: '成功',
                    };
                } else {
                    obj = {
                        off: false,
                        msg: '请按照要求填写内容',
                    };
                }
            });
            return obj;
        },
        /**
         * @description: 获取验证码
         * @return {*}
         */
        getCodes() {
            if (this.ruleForm.disabled) {
                return;
            }
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!reg.test(this.ruleForm.tel)) {
                return;
            }
            this.changetel();
            this.setTime();
            sendCode({}, this.ruleForm.tel).then((res) => { });
        },
        /**
         * @description: 头像面上传成功回调函数
         * @param {*} res
         * @param {*} file
         * @return {*}
         */
        handleAvatarSuccess1(res, file) {
            this.imageUrl = res.data[0];
            this.fileList1 = [
                {
                    url: res.data[0],
                },
            ];
            this.onChangeForm();
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 国徽面上传成功回调函数
         * @param {*} res
         * @param {*} file
         * @return {*}
         */
        handleAvatarSuccess2(res, file) {
            this.imagesUrl = res.data[0];
            this.fileList2 = [
                {
                    url: res.data[0],
                },
            ];
            this.onChangeForm();
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 手持身份证面上传成功回调函数
         * @param {*} res
         * @param {*} file
         * @return {*}
         */
        handleAvatarSuccess3(res, file) {
            this.imagerUrl = res.data[0];
            this.fileList3 = [
                {
                    url: res.data[0],
                },
            ];
            this.onChangeForm();
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 上传前文件校验函数
         * @param {*} file
         * @return {*}
         */
        beforeAvatarUpload(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isJPG = file.type === 'image/jpg';
            const isPNG = file.type === 'image/png';
            const isBMP = file.type === 'image/bmp';
            const isLt5M = file.size / 1024 / 1024 < 5;

            if (!isJPG && !isJPEG && !isPNG && !isBMP) {
                this.$message.error('请上传格式为 JPG/JPEG/PNG/BMP 的文件');
            }
            if (!isLt5M) {
                this.$message.error('上传证件照片大小需小于5MB');
            }
            return isLt5M;
        },
        /**
         * @description: 头像面移除回调函数
         * @param {*} file
         * @param {*} fileList
         * @return {*}
         */
        handleRemove1(file, fileList) {
            this.imageUrl = '';
            this.fileList1 = [];
            this.onChangeForm();
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 国徽面移除回调函数
         * @param {*} file
         * @param {*} fileList
         * @return {*}
         */
        handleRemove2(file, fileList) {
            this.imagesUrl = '';
            this.fileList2 = [];
            this.onChangeForm();
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 手持身份证面移除回调函数
         * @param {*} file
         * @param {*} fileList
         * @return {*}
         */
        handleRemove3(file, fileList) {
            this.imagerUrl = '';
            this.fileList3 = [];
            this.onChangeForm();
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 图片预览回调函数
         * @param {*} file
         * @return {*}
         */
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 手机号提交
        submitPhone(formName) {
            this.$refs.ruleForm.validate();
        },
        /**
         * @description: 刷新前保留倒计时状态
         * @return {*}
         */
        beforeunload() {
            window.addEventListener('beforeunload', this.saveSessionData);
        },
        /**
         * @description: 保存倒计时状态到sessionStorage中，刷新页面时恢复倒计时状态
         * @return {*}
         */
        saveSessionData() {
            sessionStorage.setItem(
                'getTime',
                JSON.stringify({
                    time: this.setTimeNum,
                    timer: this.timer,
                    verifyStatus: this.verifyStatus,
                })
            );
        },
        /**
         * @description: 开始验证码获取倒计时
         * @return {*}
         */
        setTime() {
            this.disabled = true;
            if (!this.timer) {
                this.timer = setInterval(() => {
                    if (this.setTimeNum === 0) {
                        this.catchCode = '重新发送';
                        this.setTimeNum = 60;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.disabled = false;
                        return false;
                    } else {
                        this.catchCode = this.setTimeNum + 's';
                        this.setTimeNum--;
                    }
                }, 1000);
            }
        },
    },
    beforeDestroy() {
        // 清除定时器
        window.removeEventListener('beforeunload', () => {
            sessionStorage.removeItem('getTime');
        });
    },
};
</script>
<style lang="less" scoped>
.el-form-item--mini.el-form-item {
    margin-bottom: 28px;
}

.el-form-item--small.el-form-item {
    margin-bottom: 28px;
}

.el-form-item__label {
    color: #383d47;
}

.el-form-item {
    text-align: left;
}

.el-input {
    width: 420px;
    height: 32px;
}

::v-deep .upload-demo .el-upload--picture-card {
    background: transparent;
    border: none;

    .on {
        position: absolute;
        top: 0;
        left: 0;
    }
}

::v-deep .el-upload-list--picture-card {
    display: block;
    position: absolute;
    z-index: 999;
}

::v-deep .el-upload--picture-card:hover {
    border-color: transparent;
    color: #2d63e0;
}

.imgtype {
    width: 380px;
    height: 109px;
    display: flex;
    justify-content: space-between;

    .el-upload--picture-card {
        img {
            width: 118px;
            height: 73px;
            border: 1px dashed #cfd4df;
            border-radius: 6px;

            &:hover {
                border: 1px solid #2d63e0;
            }
        }
    }

    p {
        position: absolute;
        bottom: 8px;
        left: 37px;
        font-size: 12px;
        color: #b0b2c4;
        letter-spacing: 0;
        line-height: 14px;
        margin-top: -10px;
    }
}

.upload-demo {
    width: 120px;
    text-align: left;
    position: relative;

    img {
        margin-bottom: 8px;
    }

    // .el-upload {
    //   border: 1px dashed #d9d9d9;
    //   border-radius: 6px;
    //   cursor: pointer;
    //   overflow: hidden;
    // }
    // .el-upload:hover {
    //   border-color: #409EFF;
    //   z-index: 999;
    // }
}

.upload_tip {
    font-size: 12px;
    color: #b0b2c4;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 2px;
    text-align: left;
    font-weight: lighter;
}

/deep/ .el-upload {
    width: 120px;
    height: 75px;
}

/deep/ .el-upload-list__item {
    width: 120px;
    height: 75px;
}

::v-deep .code_btn {
    background: rgba(45, 99, 224, 0.14);
    border-radius: 4px;
    width: 94px;
    height: 32px;
    line-height: 15px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    letter-spacing: 0;
    margin-left: 16px;
    color: #2858c7;
    opacity: 0.7;

    span {
        position: relative;
        top: -1px;
        font-weight: normal;
    }
}

.code_btn.null {
    background: #f5f7fc;
    opacity: 0.3;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #2d63e0;
    cursor: not-allowed;
    background-image: none;
    background-color: #f5f7fc;
    border-color: transparent;
}

.time_btn {
    color: #000;
    cursor: default;
}

.tick {
    margin-left: -10px;
}

.required-icon {
    color: #F56C6C;
    margin-right: 4px;
}
</style>
<!--
 * @Author: v_yerun v_yerun@baidu.com
 * @Date: 2024-06-28 16:10:05
 * @LastEditors: v_yerun v_yerun@baidu.com
 * @LastEditTime: 2025-03-11 15:55:59
 * @FilePath: /banquan-ti/baidu/feedback/copyright/src/main/resources/copyright/src/components/subject/Organization.vue
 * @Description:  账号主体信息-组织
-->
<template>
    <!-- 组织页面 -->
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-position="right"
        :rules="rules"
        label-width="125px"
        size="small"
        class="demo-ruleForm clearfix"
    >
        <el-form-item
            label="名称"
            prop="cate"
        >
            <el-input
                v-model.trim="ruleForm.cate"
                @change="onChangeForm"
                placeholder="请输入名称"
                :disabled="ruleForm.disabled"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="证件编号"
            prop="number"
        >
            <el-input
                v-model.trim="ruleForm.number"
                @change="onChangeForm"
                placeholder="请填写执照的社会统一信用代码"
                :disabled="ruleForm.disabled"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="证件原件上传"
            prop="fileList"
        >
            <el-upload
                ref="uploads"
                class="upload-demo"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                :on-exceed="handleExceed"
                :disabled="ruleForm.disabled"
                :file-list="ruleForm.fileList"
                :action="baseUrl + '/upload/pic/2'"
                accept="image/png, image/jpeg, image/jpg, image/bmp"
            >
                <img
                    :src="cardIcon"
                    alt=""
                    width="100%"
                    height="100%"
                />
                <div
                    slot="tip"
                    class="upload_tip"
                >
                    <p class="p1">请上传企业营业执照或其他组织机构的资质证明文件扫描件或数码照片，要求清晰可见，</p>
                    <p class="p2">支持.jpg.jpeg.png.bmp，图片大小不超过20M</p>
                </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
                <img
                    width="100%"
                    :src="dialogImageUrl"
                    alt=""
                />
            </el-dialog>
        </el-form-item>
        <el-form-item
            label="证件有效期"
            prop="time"
        >
            <el-date-picker
                v-model="ruleForm.time"
                class="picker"
                :disabled="ruleForm.disabled"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="['00:00:00', '23:59:59']"
                @change="onPick"
            >
            </el-date-picker>
            <el-checkbox
                v-model="ruleForm.longTime"
                :disabled="ruleForm.disabled"
                @change="longChange"
            >
                长期有效
            </el-checkbox>
        </el-form-item>
        <el-form-item
            label="法人代表姓名"
            prop="name"
        >
            <el-input
                v-model.trim="ruleForm.name"
                @change="onChangeForm"
                :disabled="ruleForm.disabled"
                placeholder="请输入法人代表姓名"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="联系人"
            prop="contacts"
        >
            <el-input
                v-model.trim="ruleForm.contacts"
                @change="onChangeForm"
                :disabled="ruleForm.disabled"
                placeholder="请输入联系人"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="邮箱"
            prop="email"
        >
            <el-input
                v-model.trim="ruleForm.email"
                @change="onChangeForm"
                :disabled="ruleForm.disabled"
                placeholder="请输入邮箱"
            ></el-input>
        </el-form-item>
        <el-form-item
            label="手机号"
            prop="tel"
        >
            <el-input
                v-model.trim="ruleForm.tel"
                placeholder="请填写联系人手机号"
                :disabled="ruleForm.disabled"
                @change="changetel"
                style="width: 310px; height: 32px"
            ></el-input>
            <el-button
                class="code_btn"
                :class="[ruleForm.tel ? '' : 'null']"
                :disabled="disabled"
                @click="getCodes"
            >
                {{ catchCode }}
            </el-button>
        </el-form-item>
        <el-form-item
            v-if="ruleForm.yanoff"
            class="last"
            label="验证码"
            prop="password"
        >
            <el-input
                :disabled="ruleForm.disabled"
                v-model.trim="ruleForm.password"
                @keyup.enter.native="submitPhone"
                placeholder="请填写验证码"
                style="width: 134px"
            ></el-input>
        </el-form-item>
        <el-form-item>
            <next-tick
                :btn1="1"
                :btn2="0"
                :btn3="1"
                :btn4="ruleForm.update ? 1 : 0"
                class="tick"
                @nextTick="nextTick('ruleForm')"
                @edit="edit"
            ></next-tick>
        </el-form-item>
    </el-form>
</template>

<script>
import { sendCode, keyword, info, verifyTelAndUserInfo } from '@/api/getData.js';
import { rTime } from '@/utils/public.js';
import NextTick from '@/components/NextTick.vue';

export default {
    name: 'organization',
    components: {
        'next-tick': NextTick, // 下一步按钮
    },
    data() {
        return {
            cardIcon: require('@/assets/img/card.png'),
            catchCode: '获取验证码',
            disabled: true,
            setTimeNum: 60,
            baseUrl: process.env.NODE_ENV === 'production' ? location.origin : '/proxy',
            timer: null, // 是否已存在计时器
            dialogImageUrl: '',
            imageUrl: [],
            timeout: false,
            dialogVisible: false,
            ruleForm: {
                edited: false,
                yanoff: false,
                longTime: false,
                cate: '',
                disabled: false,
                update: false,
                name: '',
                fileList: [],
                off: false,
                number: '',
                email: '',
                tel: '',
                password: '',
                time: '',
                contacts: '',
            }, // 组织认证表单
            rules: {
                cate: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    {
                        min: 1,
                        max: 40,
                        message: '长度在 40 个字符以内',
                        trigger: 'blur',
                    },
                ],
                time: [
                    { required: true, message: '请选择证件有效期', trigger: ['change', 'blur'] },
                    { validator: this.validateTime, trigger: ['blur', 'change'] },
                ],
                number: [
                    { required: true, message: '请输入证件编号', trigger: 'blur' },
                    {
                        min: 3,
                        max: 30,
                        message: '长度在 3 - 30 个字符以内',
                        trigger: 'blur',
                    },
                    { validator: this.validateNumber, trigger: ['blur', 'change'] }
                ],
                contacts: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '您输入的联系人长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^[\u4e00-\u9fa5]*$/,
                        message: '请填写的有效联系人',
                        trigger: 'blur',
                    },
                ],
                fileList: [
                    { type: 'array', required: true, message: '请上传图片材料', trigger: 'change' },
                ],
                name: [
                    { required: true, message: '请输入法人代表姓名', trigger: 'blur' },
                    {
                        min: 2,
                        max: 20,
                        message: '法人代表姓名长度不正确，请重新输入',
                        trigger: 'blur',
                    },
                ],
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    {
                        pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
                        message: '请输入正确的邮箱地址',
                        trigger: 'blur',
                    },
                ],
                tel: [
                    { required: true, message: '请填写联系人手机号', trigger: 'blur' },
                    {
                        min: 11,
                        max: 11,
                        message: '您输入的手机号码长度不正确,请重新输入',
                        trigger: 'blur',
                    },
                    {
                        pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                        message: '您输入的手机号码不正确,请重新输入',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请填写验证码', trigger: 'blur' },
                    {
                        pattern: /^[0-9]{4}$/,
                        message: '请填写正确的验证码',
                        trigger: 'blur',
                    },
                ],
            }, // 认证表单校验规则
            verifyStatus: false,
        };
    },
    props: {
        // 当前组件是否显示
        visible: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.beforeunload();
    },
    mounted() {
        this.init();
    },
    watch: {
        'ruleForm.tel': {
            handler(val) {
                const pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (val && pattern.test(val)) {
                    if (this.setTimeNum === 60) {
                        this.disabled = false;
                    }
                } else {
                    this.disabled = true;
                }
            },
        },
        visible: {
            handler(val) {
                let isEdit = sessionStorage.getItem('isEdit') || false;
                // 读取缓存 获取是否编辑状态
                if (val && isEdit === '1') {
                    this.ruleForm.update = true;
                }
            },
        },
    },
    methods: {
        /**
         * @description: 初始化页面，回填表单数据
         * @return {*}
         */
        init() {
            this.ruleForm.password = '';
            let offrus = {};
            let ruleForm = sessionStorage.getItem('subject');
            if (ruleForm) {
                try {
                    offrus = JSON.parse(ruleForm);
                } catch (error) {
                    offrus = {};
                }
            }
            if (!offrus.update && ruleForm !== 'undefined') {
                info().then((res) => {
                    if (res?.code === 200 && res?.data?.user_type === 2) {
                        if (res.data.user_status === 2) {
                            this.ruleForm.disabled = false;
                            this.ruleForm.off = false;
                            this.ruleForm.update = true;
                            this.$emit('edit', 1);
                        } else {
                            this.ruleForm.disabled = true;
                            this.ruleForm.off = true;
                            this.ruleForm.update = false;
                            this.$emit('edit', 0);
                            this.ruleForm.cate = res.data.user_name;
                            this.ruleForm.number = res.data.company_code;
                            this.ruleForm.fileList = [{ url: res.data.license_url }];
                            let arr = [];
                            this.ruleForm.fileList.forEach((item) => {
                                arr.push(item.url);
                            });
                            this.imageUrl = arr;
                            this.ruleForm.time = [rTime(res.data.card_start_date), rTime(res.data.card_end_date)];
                            this.ruleForm.contacts = res.data.contact_name;
                            this.ruleForm.name = res.data.legal_person;
                            this.ruleForm.email = res.data.email;
                            this.ruleForm.tel = res.data.mobile_phone;
                            let startTime = rTime(this.ruleForm.time[0]);
                            let endTime = rTime(this.ruleForm.time[1]);
                            if (startTime === '2000-01-01' && endTime === '2099-12-31') {
                                this.ruleForm.longTime = true;
                            } else {
                                this.ruleForm.longTime = false;
                            }
                        }
                    } else if (res?.data?.user_type !== 1) {
                        this.ruleForm.update = true;
                        this.$emit('edit', 1); // 标记为编辑状态
                    }
                });
            } else {
                if (ruleForm && ruleForm !== 'undefined') {
                    if (offrus.type === 'true') {
                        this.ruleForm = offrus;
                        this.imageUrl = offrus.imageUrl;
                        this.verifyStatus = this.ruleForm.verifyStatus;
                    } else {
                        this.ruleForm.update = true;
                        this.$emit('edit', 1); // 标记为编辑状态
                    }
                }
            }
            // 回显获取验证码倒计时等待时间
            const setTime = JSON.parse(sessionStorage.getItem('setTime'));
            const { time, timer, verifyStatus, disabled } = setTime || {};
            if (timer && !verifyStatus) {
                this.setTimeNum = time;
                this.disabled = disabled;
                this.setTime();
            }
        },
        /**
         * @description: 证件编号验证
         * @param {*} rule 验证规则
         * @param {*} value 输入的值
         * @param {*} callback 回调函数
         * @return {*}
         */
        validateNumber(rule, value, callback) {
            const regex = /^[A-Za-z0-9]+$/;
            if (!value) {
                callback(new Error('请输入证件编号'));
            } else if (!regex.test(value)) {
                callback(new Error('证件编号只能包含字母和数字'));
            } else {
                callback();
            }
        },
        /**
         * @description: 验证有效期
         * @return {*}
         */
        validateTime(rule, value, callback) {
            if (!Array.isArray(value) || value?.length < 2) {
                callback(new Error('请选择证件有效期'));
            } else if (Date.parse(value[1]) - Date.parse(new Date()) > 0) {
                callback();
            } else {
                callback(new Error('证件结束时间应当大于当前时间'));
            }
        },
        /**
         * @description: 选择是否长期有效
         * @return {*}
         */
        longChange() {
            this.onChangeForm();
            // 长期有效复选框
            if (this.ruleForm.longTime) {
                this.ruleForm.time = [rTime('2000-01-01'), rTime('2099-12-31')];
            } else {
                this.ruleForm.time = [];
            }
        },
        /**
         * @description: 点击编辑按钮
         * @return {*}
         */
        edit() {
            // 编辑
            this.ruleForm.edited = true;
            this.ruleForm.disabled = false;
            this.ruleForm.update = true;
            this.$emit('edit', 1);
        },
        /**
         * @description: 下一步(提交认证信息)
         * @return {*}
         */
        nextTick() {
            if (!this.ruleForm.update && this.timeout) {
                this.$notify.error({
                    title: '证件过期',
                    message: '当前证件已过期',
                });
                return;
            }
            this.$refs.ruleForm.validate(async (valid, rule) => {
                if (valid) {
                    try {
                        if (this.ruleForm.yanoff) {
                            const {
                                cate = '', // 组织名称
                                number = '', // 证件编号
                                fileList = [], // 证件图片
                                time = [], // 有效期
                                name = '', // 法人姓名
                                contacts = '', // 联系人
                                email = '', // 邮箱
                                tel = '', // 手机号码
                                password = '', // 验证码
                            } = this.ruleForm || {};
                            const res = await verifyTelAndUserInfo({
                                mobile_phone: tel,
                                verify_code: password,
                                user_info: {
                                    user_type: 2, // 认证类型 1个人 2组织
                                    user_name: cate, // 组织名称
                                    company_code: number, // 证件编号
                                    license_url: fileList[0]?.url || '', // 证件图片
                                    card_start_date: rTime(time[0]), // 有效期开始
                                    card_end_date: rTime(time[1]), // 有效期结束
                                    legal_person: name, // 法人姓名
                                    contact_name: contacts, // 联系人
                                    email, // 邮箱
                                    mobile_phone: tel, // 手机号码
                                }
                            });
                            const { code, message } = res;
                            if (code === 200) {
                                sessionStorage.setItem('active', 1);
                                if (!this.ruleForm.disabled) {
                                    sessionStorage.setItem('isEdit', 1); // 标记为编辑状态
                                    let type1 = sessionStorage.getItem('type1'); // 获取认证类型
                                    sessionStorage.setItem('type', type1); // 更新认证类型
                                }
                                this.suces();
                            } else {
                                this.$message.error(message || '认证失败');
                                return false;
                            }
                        } else {
                            // 无改动 直接缓存认证信息
                            sessionStorage.setItem('active', 1);
                            if (!this.ruleForm.disabled) {
                                sessionStorage.setItem('isEdit', 1); // 标记为编辑状态
                                let type1 = sessionStorage.getItem('type1'); // 获取认证类型
                                sessionStorage.setItem('type', type1); // 更新认证类型
                            }
                            this.suces();
                        }
                    } catch (error) {
                        this.$message.error(error?.message || '认证失败');
                    }
                } else {
                    let hasError = false; // 标记是否有错误信息
                    for (const key in rule) {
                        if (rule[key]?.[0]?.message) {
                            this.$message.error(rule[key][0].message);
                            hasError = true;
                            break; // 终止循环，避免多个弹窗
                        }
                    }
                    if (!hasError) {
                        // 只有当所有 rule 都没有 message 时才执行
                        this.$message.error('请完善认证信息！');
                    }
                    return false;
                }
            });
        },
        /**
         * @description 认证成功后的操作，缓存数据+跳转页面。
         * @return {*}
         */
        suces() {
            let imgarr = [];
            let arr = [];
            if (this.ruleForm.update) {
                this.imageUrl.forEach((item) => {
                    arr.push(item);
                });
            } else {
                this.ruleForm.fileList.forEach((item) => {
                    imgarr.push(item.url);
                });
            }
            let subject = {
                ...this.ruleForm,
                type: sessionStorage.getItem('type1'),
                imageUrl: this.ruleForm.update ? arr : imgarr,
            };
            // 将表单信息缓存起来
            keyword({
                key_word: '',
                owner_type: 0,
                lastPageNo: 0,
                page: 1,
                size: 10,
            }).then((res) => {
                sessionStorage.setItem('subject', JSON.stringify(subject));
                if (res?.data?.records?.length) {
                    this.$router.push({
                        path: '/hasownership',
                    });
                } else {
                    this.$router.push({
                        path: '/noownership',
                    });
                }
            });
        },
        /**
         * @description: 表单字段有变化时，需要重新校验验证码
         * @return {*}
         */
        onChangeForm() {
            this.ruleForm.yanoff = true;
        },
        /**
         * @description: 手机号变化事件，校验手机号码是否符合规则。
         * @return {*}
         */
        changetel() {
            this.ruleForm.yanoff = true;
            this.ruleForm.password = '';
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (reg.test(this.ruleForm.tel)) {
                this.disabled = false;
            } else {
                this.disabled = true;
            }
        },
        /**
         * @description: 获取验证码，并设置倒计时。
         * @return {*}
         */
        getCodes() {
            // 获取验证码
            if (this.ruleForm.disabled) {
                return;
            }
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!reg.test(this.ruleForm.tel)) {
                return;
            }
            this.changetel();
            this.setTime();
            sendCode({}, this.ruleForm.tel).then((res) => { });
        },
        /**
         * @description: 设置证件有效期时间，并校验有效期是否合理。
         * @param {*} maxDate
         * @return {*}
         */
        onPick(maxDate) {
            this.onChangeForm();
            if (maxDate === null) {
                this.ruleForm.longTime = false;
                return;
            }
            let startTime = rTime(this.ruleForm.time[0]);
            let endTime = rTime(this.ruleForm.time[1]);
            if (startTime === '2000-01-01' && endTime === '2099-12-31') {
                this.ruleForm.longTime = true;
            } else {
                this.ruleForm.longTime = false;
            }
            if (this.ruleForm.time === null) {
                return;
            }
            this.$refs.ruleForm.validateField('time'); // 触发校验
        },
        /**
         * @description: 上传图片成功后的回调函数。
         * @param {*} res
         * @param {*} file
         * @return {*}
         */
        handleAvatarSuccess(res, file) {
            this.onChangeForm();
            this.ruleForm.fileList = this.$refs.uploads.uploadFiles;
            let arr = [];
            this.ruleForm.fileList.forEach((item) => {
                if (item.response) {
                    item.url = item.response.data[0];
                }
                arr.push(item.url);
            });
            this.imageUrl = arr;
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 上传图片之前的校验函数。
         * @param {*} file
         * @return {*}
         */
        beforeAvatarUpload(file) {
            const isJPEG = file.type === 'image/jpeg';
            const isJPG = file.type === 'image/jpg';
            const isPNG = file.type === 'image/png';
            const isBMP = file.type === 'image/bmp';
            const isLt5M = file.size / 1024 / 1024 < 20;
            if (!isJPG && !isJPEG && !isPNG && !isBMP) {
                this.$message.error('请上传格式为 JPG/JPEG/PNG/BMP 的文件');
            }
            if (!isLt5M) {
                this.$message.error('上传证件照片大小需小于20MB');
            }
            return isLt5M;
        },
        /**
         * @description: 移除图片后的回调函数。
         * @param {*} file
         * @param {*} fileList
         * @return {*}
         */
        handleRemove(file, fileList) {
            this.onChangeForm();
            this.ruleForm.fileList = this.$refs.uploads.uploadFiles;
            let arr = [];
            this.ruleForm.fileList.forEach((item) => {
                arr.push(item.url);
            });
            this.imageUrl = arr;
            this.flag = false;
            this.$refs.ruleForm.validateField('fileList'); // 触发校验
        },
        /**
         * @description: 预览图片的回调函数。
         * @param {*} file
         * @return {*}
         */
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        /**
         * @description: 超出图片数量的回调函数。
         * @param {*} files
         * @param {*} fileList
         * @return {*}
         */
        handleExceed(files, fileList) {
            this.$message.warning('只能上传1张图片');
        },
        // 验证是否注册 暂未使用
        async hasRegistFn() {
            let phone = this.ruleForm.username;
            if (phone !== '' && this.phoneTrue) {
                if (this.disabled) {
                    this.disabled = false;
                    // eslint-disable-next-line no-undef
                    let res = await hasRegistApi({ mobile: phone });
                    if (res.success) {
                        this.getCode();
                        this.registStatus = false;
                    } else {
                        this.registStatus = true;
                    }
                }
            } else {
                this.$refs.ruleForm.validateField('username');
            }
        },
        // 获取验证码 暂未使用
        async getCode() {
            // eslint-disable-next-line no-undef
            let res = await getCodeRes({ mobile: this.ruleForm.username });
            if (!res.success) {
                if (res.message === 'isv.BUSINESS_LIMIT_CONTROL') {
                    this.$message({
                        type: 'error',
                        message: '当天发送短信已达到上限，请改天再试！',
                    });
                } else {
                    this.$message({ type: 'error', message: res.message });
                }
            } else {
                this.ruleForm.smsToken = res.data.smsToken;
                this.setTime();
            }
        },
        /**
         * @description: 提交手机号表单。
         * @param {*} formName
         * @return {*}
         */
        submitPhone(formName) {
            this.$refs.ruleForm.validate();
        },
        beforeunload() {
            window.addEventListener('beforeunload', () => {
                sessionStorage.setItem(
                    'setTime',
                    JSON.stringify({
                        time: this.setTimeNum,
                        timer: this.timer,
                        verifyStatus: this.verifyStatus,
                        disabled: this.disabled,
                    })
                );
            });
        },
        setTime() {
            this.disabled = true;
            if (!this.timer) {
                this.timer = setInterval(() => {
                    if (this.setTimeNum === 0) {
                        this.catchCode = '重新发送';
                        this.setTimeNum = 60;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.disabled = false;
                        return false;
                    } else {
                        this.catchCode = this.setTimeNum + 's';
                        this.setTimeNum--;
                    }
                }, 1000);
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', () => {
            sessionStorage.removeItem('setTime');
        });
    },
};
</script>
<style>
.el-date-table td.available:hover {
    color: #2d63e0 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2d63e0;
    border-color: #2d63e0;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #2d63e0;
}

.el-date-table td.end-date span {
    background-color: #2d63e0;
}

.el-date-table td.start-date span {
    background-color: #2d63e0;
}

.el-date-table td span {
    border-radius: 2px;
}

.el-date-table td.in-range div {
    background-color: rgba(45, 99, 224, 0.1);
}

.el-date-table td.in-range div:hover {
    background-color: rgba(45, 99, 224, 0.1);
}

.el-date-table.is-week-mode .el-date-table__row.current div {
    background-color: rgba(45, 99, 224, 0.1);
}

.el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: rgba(45, 99, 224, 0.1);
}

.el-date-table td.end-date div {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.el-date-table td.start-date div {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.el-date-table td.today span {
    color: #2d63e0;
}

[class*=' el-icon-'],
[class^='el-icon-'] {
    color: #d3d1e0;
}

[class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #fff;
}

.el-form-item__label {
    color: #383d47;
}
</style>
<style lang="less" scoped>
::v-deep .el-checkbox {
    margin-left: 50px;
}

::v-deep .el-checkbox__label {
    padding-left: 5px;
}

::v-deep .el-upload-list--picture-card {
    display: inline-block;
    height: 85px;
}

::v-deep .el-upload-list.is-disabled+.el-upload img:hover {
    cursor: no-drop;
}

.el-form-item--mini.el-form-item {
    margin-bottom: 28px;
}

.el-form-item--small.el-form-item {
    margin-bottom: 28px;
}

.el-form-item {
    text-align: left;
}

.el-input {
    width: 420px;
    height: 32px;
}

.picker {
    width: 420px;
    height: 32px;
}

.upload-demo {
    text-align: left;

    img {
        margin-bottom: 10px;
    }
}

.upload_tip {
    font-size: 12px;
    color: #b0b2c4;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: lighter;
}

/deep/ .el-upload {
    width: 120px;
    height: 75px;
}

/deep/ .el-upload-list__item {
    width: 120px;
    height: 75px;
}

::v-deep .code_btn {
    background: #f5f7fc;
    border-radius: 4px;
    width: 94px;
    height: 32px;
    line-height: 15px;
    cursor: pointer;
    font-size: 14px;
    border: none;
    letter-spacing: 0;
    margin-left: 16px;
    color: #2858c7;
    opacity: 0.7;

    span {
        position: relative;
        top: -1px;
        font-weight: normal;
    }
}

.code_btn.null {
    background: #f5f7fc;
    opacity: 0.3;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #2d63e0;
    cursor: not-allowed;
    background-image: none;
    background-color: #f5f7fc;
    border-color: transparent;
}

.time_btn {
    color: #000;
    cursor: default;
}

.tick {
    margin-left: -10px;
}
</style>